import * as actionTypes from '~/store/actions/actionTypes'
import { updateObject } from '~/helpers'

const initialState = {
  error: null,
  loading: false,
  loggedOut: false,
  authenticationRedirectPath: '/',
  user: null,
  token: null
}

const authenticationStart = (state) => {
  return updateObject(state, {
    error: null,
    loggedOut: false,
    loading: true
  })
}

const authenticationSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loggedOut: false,
    loading: false,
    ...action.payload
  })
}

const authenticationFail = (state, action) => {
  return updateObject(state, {
    error: action.payload.error || state.error,
    loading: false,
    user: null
  })
}

const authenticationLoggedOut = (state) => {
  return updateObject(state, {
    user: null,
    loggedOut: true,
    token: null
  })
}

const authenticationLoggedOutFinished = (state) => {
  return updateObject(state, {
    loggedOut: false
  })
}

const setAuthenticationRedirectPath = (state, action) => {
  return updateObject(state, {
    authenticationRedirectPath: action.payload.path
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTHENTICATION_START:
      return authenticationStart(state, action)

    case actionTypes.AUTHENTICATION_SUCCESS:
      return authenticationSuccess(state, action)

    case actionTypes.AUTHENTICATION_FAIL:
      return authenticationFail(state, action)

    case actionTypes.AUTHENTICATION_LOGGED_OUT:
      return authenticationLoggedOut(state, action)

    case actionTypes.AUTHENTICATION_LOGGED_OUT_FINISHED:
      return authenticationLoggedOutFinished(state, action)

    case actionTypes.SET_AUTHENTICATION_REDIRECT_PATH:
      return setAuthenticationRedirectPath(state, action)

    default: return state
  }
}

export default reducer
